import React, { useEffect, useState } from "react";
import SideBar from "../component/SideBar";
import { Controller, set, useForm } from "react-hook-form";
import InputField from "../common/components/inputfield/InputField";
import addIcon from "../common/assets/images/add_circle.svg";
import wrong from "../common/assets/images/wrong.svg";
import axios from "axios";
import { useMutation, useQuery } from "react-query";
import { Navigate, useLocation, useNavigate } from "react-router";
import AlertModal from "../common/components/popupmodel/AlertModal";
import ModalImage from "../common/components/ModalImage";

export const getViewTechnicians = async () => {
  const response = await axios.get(
    // "https://api.2kvirtualworld.com/CRM/technicians"
    `${process.env.REACT_APP_HOST}/technicians`
  );
  return response.data;
};

export default function NewTechnician() {
  const { control, handleSubmit, setValue, reset, watch } = useForm();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [base64Image, setBase64Image] = useState({});
  const [open, setOpen] = useState(false);
  const [UpdateOpen, setUpdateOpen] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [mobileNoTyped, setMobileNoTyped] = useState("");
  const { state } = useLocation();
  const navigate = useNavigate();
  const { documentUploads } = watch();
  // console.log("documentuploads ", documentUploads);
  console.log("files ", state);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalImage, setModalImage] = useState("");
  // Open Modal and set the image
  const openModal = (image) => {
    setModalImage(image);
    setIsModalOpen(true);
  };

  // Close Modal
  const closeModal = () => {
    setIsModalOpen(false);
    setModalImage("");
  };

  useEffect(() => {
    if (state) {
      setBase64Image({
        aadharFile: state?.adharImage,
        panFile: state?.panImage,
        photoFile: state?.photo,
        // documentUpload: state?.documentUpload,
      });

      setValue("firstName", state?.firstName || "");
      setValue("lastName", state?.lastName || "");
      setValue("address", state?.address || "");
      setValue("mobileNo", state?.mobileNo || "");
      setValue("alternativeNumber", state?.alternativeNumber || "");
      setValue("email", state?.emailId || "");
      setValue("role", state?.role || "");
      setValue("department", state?.department || "");
      setValue("aadhar", state?.adharNo || "");
      setValue("panNo", state?.panNo || "");
      setValue("jobType", state?.jobType || "");
      setValue("place", state?.place || "");
      setValue("pinCode", state?.pinCode || "");
    }
  }, [state, setValue]);
  const handleImageInputChange = (event, fileName) => {
    // console.log("event", event.target.files[0], fileName);

    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64String = reader.result;
        const base64LetterRemove = base64String.substring(
          base64String.indexOf(",") + 1
        );
        setBase64Image({ ...base64Image, [fileName]: base64LetterRemove });
      };
      reader.onerror = (error) => {
        console.error(
          "Error occurred while converting image to base64:",
          error
        );
      };
    }
  };

  const removeKey = (fileName) => {
    // console.log("value", fileName);
    setBase64Image({ ...base64Image, [fileName]: "" });
  };

  const { mutate, isLoading, isError, isSuccess } = useMutation(
    async (postData) => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_HOST}/technicians`,
          postData
        );
        setOpen("TECHNICIAN ADDED");
        return response.data;
      } catch (error) {
        console.error("Error in mutation:", error);
        throw error;
      }
    }
  );

  const UpdateFormValue = useMutation(
    (postData) =>
      axios.put(
        `${process.env.REACT_APP_HOST}/technicians/${state?.employeeID}`,
        postData
      ),
    {
      onSuccess: (data) => {
        setUpdateOpen("TECHNICION UPDATED");
      },
      onError: (error) => {
        // alert(error)
        console("Mutation failed:", error);
      },
    }
  );
  // console.log("selectedFiles", selectedFiles);
  // Handle file input change

  // Convert file to Base64 string without metadata
  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        let base64String = reader.result; // Get Base64 string
        // Remove the prefix (data:image/jpeg;base64, or similar)
        const base64Data = base64String.split(",")[1]; // This removes the metadata part
        resolve(base64Data); // Return only the Base64 data (no metadata)
      };
      reader.onerror = reject;
      reader.readAsDataURL(file); // Read file as Base64 string
    });
  };

  // Multiple file Upload

  const handleFileChange = (onChange) => async (e) => {
    const files = Array.from(e.target.files); // Convert FileList to array
    setSelectedFiles((prevFiles) => {
      const newFiles = [...prevFiles, ...files]; // Append new files to the existing list
      return newFiles;
    });

    // Convert each file to Base64 and update form state
    const base64Files = await Promise?.all(
      files?.map((file) => convertToBase64(file))
    );

    // Get the current documentUploads field value (existing Base64 files)
    const currentBase64Data = watch("documentUploads") || [];

    // Update form state by appending new Base64 data to the existing array
    onChange([...currentBase64Data, ...base64Files]);
  };

  // Remove a file from the list
  const removeFile = async (index, onChange) => {
    // Remove file from selectedFiles
    const newSelectedFiles = selectedFiles?.filter((_, i) => i !== index);
    setSelectedFiles(newSelectedFiles);

    // Convert the remaining files to Base64
    const remainingBase64Files = await Promise.all(
      newSelectedFiles?.map((file) => convertToBase64(file))
    );

    // Directly update the form state with the remaining Base64 files
    onChange(remainingBase64Files); // Update form state directly with the new Base64 data
  };

  // const handleMobileChange = (e) => {
  //   setMobileNoTyped(e.target.value);
  // };
  const { mobileNo } = watch();
  // Fetching technician data using react-query
  const { data, refetch } = useQuery("getViewTechnicians", getViewTechnicians, {
    enabled: false,
  });

  useEffect(() => {
    if (mobileNo && data) {
      const technicianDetails =
        data &&
        data?.find((item) => Number(item?.mobileNo) === Number(mobileNo));

      if (technicianDetails && technicianDetails) {
        // Set form values when technician details are found
        setBase64Image({
          aadharFile: technicianDetails?.adharImage,
          panFile: technicianDetails?.panImage,
          photoFile: technicianDetails?.photo,
          // documentUpload: state?.documentUpload,
        });
        setValue("firstName", technicianDetails?.firstName || "");
        setValue("lastName", technicianDetails?.lastName || "");
        setValue("address", technicianDetails?.address || "");
        setValue("mobileNo", technicianDetails?.mobileNo || "");
        setValue(
          "alternativeNumber",
          technicianDetails?.alternativeNumber || ""
        );
        setValue("email", technicianDetails?.emailId || "");
        setValue("role", technicianDetails?.role || "");
        setValue("department", technicianDetails?.department || "");
        setValue("aadhar", technicianDetails?.adharNo || "");
        setValue("panNo", technicianDetails?.panNo || "");
        setValue("jobType", technicianDetails?.jobType || "");
        setValue("place", technicianDetails?.place || "");
        setValue("pinCode", technicianDetails?.pinCode || "");
      }
    }
  }, [mobileNo, data, setValue]);

  useEffect(() => {
    if (mobileNo) {
      refetch();
    }
  }, [mobileNo, refetch]);
  const Submit = (data) => {
    const formValue = {
      firstName: data?.firstName,
      lastName: data?.lastName,
      address: data?.address,
      mobileNo: data?.mobileNo,
      alternativeNumber: data?.alternativeNumber,
      emailId: data?.email,
      role: data?.role,
      department: data?.department,
      adharNo: data?.aadhar,
      panNo: data?.panNo,
      panImage: base64Image?.panFile,
      adharImage: base64Image?.aadharFile,
      photo: base64Image?.photoFile,
      documentUploads: data?.documentUploads,
      jobType: data?.jobType,
      place: data?.place,
      pinCode: data?.pinCode,
    };
    if (state && state?.employeeID) {
      UpdateFormValue.mutate(formValue);
    } else {
      mutate(formValue);
    }
  };

  return (
    <div className="w-100 p-3 px-4 ">
      <div
        className="row bg-white px-3 pb-4"
        style={{
          height: "95vh",
          borderRadius: "10px",
          overflowY: "scroll",
          scrollbarWidth: "thin",
        }}
      >
        <header
          className="fs-2 fw-bold py-2 mb-3 ps-4 text-center"
          style={{
            height: "10%",
            position: "sticky",
            top: "0px",
            backgroundColor: "#fff",
          }}
        >
          {state && state ? <>EDIT TECHNICIAN</> : <>NEW TECHNICIAN</>}
        </header>
        <section className="py-1">
          <form onSubmit={handleSubmit(Submit)}>
            <div className=" col-12 mb-3  d-flex">
              <div className="col-6 ">
                <label className="col-5 addUser_ticket_label">First Name</label>
                <Controller
                  control={control}
                  name="firstName"
                  render={(field) => (
                    <InputField
                      addUserInputCss="--addUser_inputFields"
                      {...field}
                      type="text"
                    />
                  )}
                  // rules={{ required: true }}
                />
              </div>
              <div className="col-6 ">
                <label className="col-5 addUser_ticket_label">Last Name</label>
                <Controller
                  control={control}
                  name="lastName"
                  render={(field) => (
                    <InputField
                      // style = {{margin}}
                      addUserInputCss="--addUser_inputFields"
                      {...field}
                      type="text"
                    />
                  )}
                  // rules={{ required: true }}
                />
              </div>
            </div>
            <div className="col-12 mb-3">
              <div className="d-flex">
                <label
                  style={{ width: "20%" }}
                  className="addUser_ticket_label"
                >
                  Address
                </label>
                <Controller
                  control={control}
                  name="address"
                  render={(field) => (
                    <InputField
                      // style = {{margin}}
                      addUserInputCss=" col-9 --addUser_companyName_inputField"
                      {...field}
                      type="text"
                    />
                  )}
                  // rules={{ required: true }}
                />
              </div>
            </div>
            <div className=" col-12 mb-3 d-flex">
              <div className="col-6">
                <label className="col-5 addUser_ticket_label">Place</label>
                <Controller
                  control={control}
                  name="place"
                  render={(field) => (
                    <InputField
                      {...field}
                      addUserInputCss="--addUser_inputFields"
                      type="text"
                    />
                  )}
                  // rules={{ required: true }}
                />
              </div>
              <div className="col-6 ">
                <label className="col-5 addUser_ticket_label">Pincode</label>
                <Controller
                  control={control}
                  name="pinCode"
                  render={(field) => (
                    <InputField
                      {...field}
                      addUserInputCss="--addUser_inputFields"
                      type="number"
                    />
                  )}
                  // rules={{ required: true }}
                />
              </div>
            </div>
            <div className=" col-12 mb-3 d-flex">
              <div className="col-6 ">
                <label className="col-5 addUser_ticket_label">Mobile No</label>
                <Controller
                  control={control}
                  name="mobileNo"
                  render={(field) => (
                    <InputField
                      // style = {{margin}}
                      addUserInputCss=" --addUser_inputFields"
                      {...field}
                      type="text"
                    />
                  )}
                  // rules={{ required: true }}
                />
              </div>
              <div className="col-6 ">
                <label className="col-5 addUser_ticket_label">
                  Alternate No
                </label>
                <Controller
                  control={control}
                  name="alternativeNumber"
                  render={(field) => (
                    <InputField
                      // style = {{margin}}
                      addUserInputCss=" --addUser_inputFields"
                      {...field}
                      type="text"
                    />
                  )}
                  // rules={{ required: true }}
                />
              </div>
            </div>

            <div className="col-12 mb-3">
              <div className="d-flex">
                <label
                  style={{ width: "20%" }}
                  className="addUser_ticket_label"
                >
                  Email ID
                </label>
                <Controller
                  control={control}
                  name="email"
                  render={(field) => (
                    <InputField
                      // style = {{margin}}
                      addUserInputCss=" col-9 --addUser_companyName_inputField"
                      {...field}
                      type="text"
                    />
                  )}
                  // rules={{ required: true }}
                />
              </div>
            </div>

            <div className=" col-12 mb-3 d-flex">
              <div className="col-6 ">
                <label className="col-5 addUser_ticket_label">Role</label>
                <Controller
                  control={control}
                  // name="role"
                  name="role"
                  render={(field) => (
                    <InputField
                      {...field}
                      addUserInputCss="--addUser_inputFields"
                      type="text"
                    />
                  )}
                />
              </div>
              <div className="col-6 ">
                <label className="col-5 addUser_ticket_label">Department</label>
                <Controller
                  control={control}
                  name="department"
                  render={(field) => (
                    <InputField
                      {...field}
                      addUserInputCss="--addUser_inputFields"
                      type="text"
                    />
                  )}
                  // rules={{ required: true }}
                />
              </div>
            </div>
            <div className=" col-12 mb-3 d-flex">
              <div className="col-6 ">
                <label className="col-5 addUser_ticket_label">Job Type</label>
                <Controller
                  name="jobType"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <select {...field} className="--addUser_inputFields">
                      <option value="">Job Type</option>
                      <option value="Full Time">Full Time</option>
                      <option value="Part Time">Part Time</option>
                    </select>
                  )}
                />
              </div>
            </div>

            <div className=" col-12 mb-3 d-flex">
              <div className="col-6">
                <label className="col-5 addUser_ticket_label">AADHAR</label>
                <Controller
                  control={control}
                  name="aadhar"
                  render={(field) => (
                    <InputField
                      {...field}
                      addUserInputCss="--addUser_inputFields"
                      type="text"
                    />
                  )}
                />
              </div>
              <div className="col-6">
                <label
                  htmlFor="input-file"
                  className="col-6 aadharFileInput text-center  justify-content-center align-items-center "
                >
                  UPLOAD A FILE HERE{" "}
                  <img className="h-75" src={addIcon} alt="" />
                </label>
                <img
                  style={{ height: "30px", marginLeft: "10px" }}
                  alt=""
                  src={`data:image/jpeg;base64,${base64Image?.aadharFile}`}
                />
                {base64Image?.aadharFile && (
                  <img
                    onClick={() => {
                      removeKey("aadharFile");
                    }}
                    className=" ps-2 h-75"
                    src={wrong}
                    alt=""
                  />
                )}

                <Controller
                  control={control}
                  name="aadharFile"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <input
                      className="fileInput"
                      value={value}
                      // onChange={onChange}
                      onChange={(e) =>
                        onChange(handleImageInputChange(e, "aadharFile"))
                      }
                      type="file"
                      name="uploadfile"
                      id="input-file"
                      style={{ display: "none" }}
                    />
                  )}
                />
              </div>
            </div>

            <div className=" col-12 mb-3 d-flex">
              <div className="col-6">
                <label className="col-5 addUser_ticket_label">PAN</label>
                <Controller
                  control={control}
                  name="panNo"
                  render={(field) => (
                    <InputField
                      {...field}
                      addUserInputCss="text-uppercase panCard_inputFields"
                      type="text"
                    />
                  )}
                />
              </div>
              <div className="col-6">
                {/* <label className="col-5 addUser_ticket_label">AADHAR</label> */}
                <label
                  for="panImg"
                  className="col-6 aadharFileInput text-center  justify-content-center align-items-center "
                >
                  UPLOAD A FILE HERE{" "}
                  <img className="h-75" src={addIcon} alt="" />
                </label>
                <img
                  style={{ height: "30px", marginLeft: "10px" }}
                  alt=""
                  src={`data:image/jpeg;base64,${base64Image?.panFile}`}
                />
                {base64Image?.panFile && (
                  <img
                    onClick={() => {
                      removeKey("panFile");
                    }}
                    className=" ps-2 h-50"
                    src={wrong}
                    alt=""
                  />
                )}
                <Controller
                  control={control}
                  name="panFile"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <input
                      className="fileInput"
                      // onChange={onChange}
                      onChange={(e) =>
                        onChange(handleImageInputChange(e, "panFile"))
                      }
                      type="file"
                      name="uploadfile"
                      id="panImg"
                      style={{ display: "none" }}
                    />
                  )}
                />
              </div>
            </div>

            <div className="col-12 mb-3">
              <div className="d-flex">
                <label
                  style={{ width: "20%" }}
                  className="addUser_ticket_label"
                >
                  Photo
                </label>

                <label
                  for="photoImg"
                  className="col-8 ms-2 aadharFileInput text-center d-flex justify-content-center align-items-center "
                >
                  UPLOAD A FILE HERE{" "}
                  <img className="h-75" src={addIcon} alt="" />
                </label>
                <img
                  style={{ height: "30px", marginLeft: "10px" }}
                  alt=""
                  src={`data:image/jpeg;base64,${base64Image?.photoFile}`}
                />
                {base64Image?.photoFile && (
                  <img
                    onClick={() => {
                      removeKey("photoFile");
                    }}
                    className=" ps-2 h-75"
                    src={wrong}
                    alt=""
                  />
                )}

                <Controller
                  control={control}
                  name="photoFile"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <input
                      className=" ms-2 "
                      type="file"
                      name="uploadfile"
                      id="photoImg"
                      style={{ display: "none" }}
                      onChange={(e) =>
                        onChange(handleImageInputChange(e, "photoFile"))
                      }
                    />
                  )}
                />
              </div>
            </div>
            <div className="col-12 mb-3">
              <div className="d-flex">
                <label
                  style={{ width: "20%" }}
                  className="addUser_ticket_label"
                >
                  Document Upload
                </label>

                <label
                  for="documentUpload"
                  className="col-8 ms-2 aadharFileInput text-center d-flex justify-content-center align-items-center "
                >
                  UPLOAD A FILE HERE{" "}
                  <img className="h-75" src={addIcon} alt="" />
                </label>
              </div>
              <div>
                {selectedFiles && selectedFiles?.length > 0 && (
                  <ul>
                    {selectedFiles?.map((file, index) => (
                      <li key={index}>
                        {file?.name}
                        <button
                          type="button"
                          onClick={() =>
                            removeFile(index, (newData) =>
                              setValue("documentUploads", newData)
                            )
                          } // Correct removeFile call
                          style={{
                            marginLeft: "10px",
                            cursor: "pointer",
                            border: "none",
                            color: "red",
                          }}
                        >
                          X
                        </button>
                      </li>
                    ))}
                  </ul>
                )}
                {state &&
                  state?.documentUploads?.length > 0 &&
                  state?.documentUploads?.map((data, index) => {
                    return (
                      <>
                        <img
                          key={index}
                          src={`data:image/jpeg;base64,${data}`}
                          alt="no-image"
                          style={{
                            width: "60px",
                            height: "60px",
                            marginRight: "10px",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            openModal(data);
                          }}
                        />
                        {/* src={`data:image/jpeg;base64,${image}`} */}
                      </>
                    );
                  })}
              </div>
              <Controller
                name="documentUploads"
                control={control}
                defaultValue={[]}
                render={({ field: { onChange } }) => (
                  <div>
                    <input
                      type="file"
                      id="documentUpload"
                      accept="*/*"
                      multiple
                      onChange={handleFileChange(onChange)} // Handle file change
                      style={{ display: "none" }} // Hide the default file input
                    />
                  </div>
                )}
              />
            </div>

            <div className="col-12 addUser_submitBtn_field">
              <button className=" col-5 addUser_submitBtn" type="submit">
                {isLoading ? (
                  <div className="spinner-border text-light" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : (
                  "SUBMIT"
                )}
              </button>
            </div>
          </form>
        </section>
        <AlertModal
          open={open}
          setOpen={setOpen}
          setUpdateOpen={setUpdateOpen}
          UpdateOpen={UpdateOpen}
          navigate={"/viewTechnicians"}
          setErrorOpen={setErrorOpen}
          errorOpen={errorOpen}
        />
        {isModalOpen && <ModalImage image={modalImage} onClose={closeModal} />}
      </div>
    </div>
  );
}
