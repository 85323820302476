import "./App.css";
import Navigation from "./common/navigation/Navigation";

function App() {
  return (
    <>
      <Navigation />
    </>
  );
}

export default App;
