import React, { useEffect, useState } from "react";
import deleteIcon from "../../assets/images/delete-black.png";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import pencilIcon from "../../assets/images/pencil.png";
import downloadIcon from "../../assets/images/download.png";
import searchIcon from "../../assets/images/search.png";
import axios from "axios";
import DeletePopup from "../popupmodel/DeletePopup";
import close from "../../assets/images/close.png";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import sandtimer from "../../assets/images/sandtimer.png";
const ViewUser = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [deletePopup, setDeletePopup] = useState(false);
  const [completePopup, setCompletePopup] = useState(false);
  const [technicianId, setTechnicianId] = useState("");
  const navigate = useNavigate();
  const [filteredData, setFilteredData] = useState([]);
  const getViewUser = async () => {
    const response = await axios.get(
      // `https://api.2kvirtualworld.com/CRM/registers`
      `${process.env.REACT_APP_HOST}/registers`
    );
    return response?.data;
  };
  const deleteItem = async (id) => {
    await axios.delete(
      `${process.env.REACT_APP_HOST}/registers/deleteStudent/${id}`
    );
  };
  const { data, isLoading, isError, refetch } = useQuery(
    "getViewUser",
    getViewUser
  );
  let details = data && data;

  useEffect(() => {
    if (data && data) {
      const applySearchFilter = () => {
        const result =
          data &&
          data?.filter((item) => {
            return searchTerm
              ? (item?.userId ?? "")
                  .toString()
                  .toLowerCase()
                  .startsWith(searchTerm.toLowerCase()) ||
                  (item?.firstName ?? "")
                    .toString()
                    .toLowerCase()
                    .startsWith(searchTerm.toLowerCase()) ||
                  (item?.lastName ?? "")
                    .toString()
                    .toLowerCase()
                    .startsWith(searchTerm.toLowerCase()) ||
                  (item?.place ?? "")
                    .toString()
                    .toLowerCase()
                    .startsWith(searchTerm.toLowerCase()) ||
                  (item?.altMobileNumber ?? "")
                    .toString()
                    .toLowerCase()
                    .startsWith(searchTerm.toLowerCase()) ||
                  (item?.mobileNumber ?? "")
                    .toString()
                    .toLowerCase()
                    .startsWith(searchTerm.toLowerCase())
              : true;
          });

        setFilteredData(result);
      };

      applySearchFilter();
    }
  }, [data, searchTerm]);

  // Mutation to handle technician deletion
  const mutation = useMutation(deleteItem, {
    onSuccess: () => {
      refetch();
    },
    onError: (err) => {
      console.error("Error deleting technician:", err);
    },
  });

  // Confirm the deletion
  const handleDeleteConfirmation = () => {
    if (technicianId) {
      mutation.mutate(technicianId);
    }
  };
  // Function to handle Excel download
  const downloadExcel = () => {
    // Determine data to export based on filter
    const exportData =
      filteredData && filteredData?.length > 0 ? filteredData : data;

    // Prepare data for the table worksheet
    const tableDatas = exportData?.map((data, index) => ({
      "S.NO": index + 1,
      "USER ID": data?.userId || "NULL",
      "USER NAME": `${data?.firstName} ${data?.lastName}` || "NULL",
      "MOBILE NUMBER": data?.mobileNumber || "NULL",
      "COMPANY NAME": data?.companyName || "NULL",
      "COMPANY ADDRESS": data?.companyAddress || "NULL",
      PLACE: data?.place || "",
      "COMPANY TYPE": data?.companyType || "NULL",
      ROLE: data?.role || "NULL",
      PINCODE: data?.pincode || "NULL",
      EMAILID: data?.emailId || "NULL",
    }));

    // Create table worksheet with headers
    const tableWorksheet = XLSX.utils.json_to_sheet(tableDatas, {
      header: [
        "USER ID",
        "USER NAME",
        "MOBILE NUMBER",
        "COMPANY NAME",
        "COMPANY ADDRESS",
        "PLACE",
        "COMPANY TYPE",
        "ROLE",
        "PINCODE",
        "EMAILID",
      ],
      skipHeader: false, // Ensure the header is included
    });

    // Create summary data
    const summaryData = [
      {
        Label: "USER ID",
        Value: exportData?.map((data) => data?.userId).join(", ") || "NULL",
      },
      {
        Label: "USER NAME",
        Value:
          exportData
            ?.map((data) => `${data?.firstName} ${data?.lastName}`)
            .join(", ") || "NULL",
      },

      {
        Label: "MOBILE NUMBER",
        Value:
          exportData?.map((data) => data?.mobileNumber).join(", ") || "NULL",
      },
      {
        Label: "COMPANY NAME",
        Value:
          exportData?.map((data) => data?.companyName).join(", ") || "NULL",
      },
      {
        Label: "COMPANY ADDRESS",
        Value:
          exportData?.map((data) => data?.companyAddress).join(", ") || "NULL",
      },
      {
        Label: "COMPANY TYPE",
        Value:
          exportData?.map((data) => data?.companyType).join(", ") || "NULL",
      },
      {
        Label: "PINCODE",
        Value: exportData?.map((data) => data?.pincode).join(", ") || "NULL",
      },
      {
        Label: "ROLE",
        Value: exportData?.map((data) => data?.role).join(", ") || "NULL",
      },
      {
        Label: "EMAILID",
        Value: exportData?.map((data) => data?.emailId).join(", ") || "NULL",
      },
    ];

    // Create summary worksheet with headers
    const summaryWorksheet = XLSX.utils.json_to_sheet(summaryData, {
      header: ["Label", "Value"],
      skipHeader: false,
    });

    // Create a new workbook and add the worksheets
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, tableWorksheet, "Table Data");
    XLSX.utils.book_append_sheet(workbook, summaryWorksheet, "Summary Data");

    // Generate the Excel file
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    // Save the Excel file
    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(blob, "New users.xlsx");
  };

  return (
    <>
      <div className="overview-main-block my-4">
        <div className="overview-heading-block">
          <h6 className="overview-head">VIEW USER</h6>
          <div className="overview-search-block">
            <div
              className="user-download-block"
              onClick={() => downloadExcel()}
            >
              <div className="search-icon">
                <img src={downloadIcon} alt="no-image" className="crm-image" />
              </div>
              <button className="overview-btn">DOWNLOAD</button>
            </div>
            <div className="user-search-block">
              <input
                type="search"
                className="table-search"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <div className="search-icon">
                <img src={searchIcon} alt="no-image" className="crm-image" />
              </div>
            </div>
          </div>
        </div>
        <div className="table-responsive overview-table-block">
          <table className="table">
            <thead className="table-heading">
              <tr>
                <th className="view-user-table-head">User ID</th>
                <th className="view-user-table-head">First Name</th>
                <th className="view-user-table-head">Last Name</th>
                <th className="view-user-table-head">Mobile No</th>
                <th className="view-user-table-head">Alternate No</th>
                <th className="view-user-table-head">Company Name</th>
                <th className="view-user-table-head">Company Address</th>
                <th className="view-user-table-head">Place</th>
                <th className="view-user-table-head">Pincode</th>
                <th className="view-user-table-head">Company Type</th>
                <th className="view-user-table-head">Role</th>
                <th className="view-user-table-head">Email ID</th>
                <th className="view-user-table-head">Edit</th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                <tr style={{ height: "140PX", position: "relative" }}>
                  <div class="wrapper">
                    <div class="circle"></div>
                    <div class="circle"></div>
                    <div class="circle"></div>
                    <div class="shadow"></div>
                    <div class="shadow"></div>
                    <div class="shadow"></div>
                    <span>Loading</span>
                  </div>
                </tr>
              ) : isError ? (
                <tr>
                  <p
                    style={{
                      color: "#000",
                      fontSize: "18px",
                      fontWeight: 800,
                    }}
                  >
                    No Network...
                  </p>
                </tr>
              ) : filteredData && filteredData?.length > 0 ? (
                filteredData?.map((item, i) => {
                  return (
                    <>
                      <tr key={i}>
                        <td className="view-user-table-data">
                          {item?.userId || ""}
                        </td>
                        <td className="view-user-table-data">
                          {item?.firstName || ""}
                        </td>
                        <td className="view-user-table-data">
                          {item?.lastName || ""}
                        </td>
                        <td className="view-user-table-data">
                          {item?.mobileNumber || ""}
                        </td>
                        <td className="view-user-table-data">
                          {item?.altMobileNumber || ""}
                        </td>
                        <td className="view-user-table-data">
                          {item?.companyName || ""}
                        </td>
                        <td className="view-user-table-data">
                          {item?.companyAddress || ""}
                        </td>
                        <td className="view-user-table-data">
                          {item?.place || ""}
                        </td>
                        <td className="view-user-table-data">
                          {item?.pincode || ""}
                        </td>
                        <td className="view-user-table-data">
                          {item?.companyType || ""}
                        </td>
                        <td className="view-user-table-data">
                          {item?.role || ""}
                        </td>
                        <td className="view-user-table-data">
                          {item?.emailId || ""}
                        </td>
                        <td className="view-user-table-data">
                          <img
                            src={pencilIcon}
                            onClick={() => {
                              navigate("/newUser", { state: item });
                            }}
                            style={{
                              width: "25px",
                              height: "25px",
                              cursor: "pointer",
                            }}
                          />
                          <img
                            src={deleteIcon}
                            onClick={() => {
                              setDeletePopup(true);
                              setTechnicianId(item?.userId);
                            }}
                            style={{
                              width: "25px",
                              height: "25px",
                              cursor: "pointer",
                            }}
                          />
                        </td>
                      </tr>
                    </>
                  );
                })
              ) : (
                <>
                  <tr>
                    <td colSpan="12" className="text-center">
                      No Records Founds
                    </td>
                  </tr>
                </>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <DeletePopup
        isOpen={deletePopup}
        backgroundColor="#004bcb"
        maxWidth="350px"
      >
        <div
          className="close-icon-block"
          onClick={() => {
            setDeletePopup(false);
          }}
        >
          <img src={close} alt="no-image" className="search-icon" />
        </div>
        <div className="delete-popup-block">
          <p className="delete-content">Are You Sure</p>
          <div className="d-flex gap-2 my-2">
            <button
              className="delete-popup-btn"
              onClick={() => {
                handleDeleteConfirmation();
                setCompletePopup(true);
              }}
            >
              OK
            </button>
            <button
              className="delete-popup-btn"
              onClick={() => {
                setDeletePopup(false);
              }}
            >
              CANCEL
            </button>
          </div>
        </div>
      </DeletePopup>
      <DeletePopup
        isOpen={completePopup}
        maxWidth="350px"
        backgroundColor="#d4edda"
      >
        <div className="delete-sub-popup px-3 py-2">
          <p className="delete-sub-popup-content">
            Technician ID has been Deleted
          </p>
          <img src={sandtimer} />
          <button
            className="delete-sub-popup-btn"
            onClick={() => {
              setCompletePopup(false);
              setDeletePopup(false);
            }}
          >
            Done
          </button>
        </div>
      </DeletePopup>
    </>
  );
};

export default ViewUser;
