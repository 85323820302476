import React from "react";
import ViewTechnician from "../../common/components/viewtechnicianscomp/ViewTechnician";

const ViewTechnicians = () => {
  return (
    <>
      <section className="overviewMainSection">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <ViewTechnician/>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ViewTechnicians;
