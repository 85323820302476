import React from "react";

const InputField = (props) => {
  return (
    <>
      <input
        {...props.field}
        className={`${props?.login_input_field} ${props?.addUserInputCss} ${props?.userpasswordfield}
        ${props?.transportInput}
        `}
        type={props.type}
        placeholder={props.placeholder}
        title={props.title}
      />
    </>
  );
};

export default InputField;
