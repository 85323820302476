import React, { useRef, useEffect } from "react";
import close from "../../assets/images/close.png";
const PopupModal = ({
  isOpen,
  onClose,
  children,
  maxWidth,
  backgroundColor,
}) => {
  const popupRef = useRef(null);

  // Function to handle clicks outside the popup
  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      onClose();
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, [isOpen]);
  if (!isOpen) return null;
  return (
    <>
      <div className="popup-overlay">
        <div
          className="popup-content"
          ref={popupRef}
          style={{ backgroundColor, maxWidth }}
        >
          <div className="close-icon-block" onClick={onClose}>
            <img src={close} alt="no-image" className="search-icon" />
          </div>
          {children}
        </div>
      </div>
    </>
  );
};

export default PopupModal;
