import React, { useEffect, useState } from "react";
import InputField from "../common/components/inputfield/InputField";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useLocation } from "react-router";
import { useMutation } from "react-query";
import addIcon from "../common/assets/images/add_circle.svg";
import wrong from "../common/assets/images/wrong.svg";
import axios from "axios";
import { WithContext as ReactTags } from "react-tag-input";
import AlertModal from "../common/components/popupmodel/AlertModal";

export default function AddProduct() {
  const [productType, setProductType] = useState("");
  // Function to get the current system time in HH:mm format
  const getCurrentTime = () => {
    const now = new Date();
    return now.toTimeString().slice(0, 5); // Format time as HH:mm (e.g., "14:30")
  };

  // State to hold the current time from system
  const [currentTime, setCurrentTime] = useState(getCurrentTime());
  const { control, handleSubmit, setValue, watch, register } = useForm({
    defaultValues: {
      selectSlot: currentTime,
    },
  });
  // Update time every minute
  useEffect(() => {
    const interval = setInterval(() => {
      const newTime = getCurrentTime();
      setCurrentTime(newTime);
      setValue("selectSlot", newTime);
    }, 60000); // Update every minute (60000ms)

    // Cleanup the interval when the component is unmounted
    return () => clearInterval(interval);
  }, [setValue]);
  const [base64Image, setBase64Image] = useState(null);
  const [tags, setTags] = useState([]);
  const [open, setOpen] = useState(false);
  const [UpdateOpen, setUpdateOpen] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const { state } = useLocation();

  const { userForm, subProduct } = watch();
  const tagsTextArray = tags?.map((item) => item?.text);
  const userFormContainerArray = userForm?.map((item) => item?.complaint);
  console.log("userFormContainerArray", userFormContainerArray);

  const { fields, append, remove } = useFieldArray({
    name: "userForm",
    control,
  });

  const handleImageInputChange = (event, fileName) => {
    console.log("event", event.target.files[0]);

    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64String = reader.result;
        const base64LetterRemove = base64String.substring(
          base64String.indexOf(",") + 1
        );
        setBase64Image({ ...base64Image, [fileName]: base64LetterRemove });
      };
      reader.onerror = (error) => {
        console.error(
          "Error occurred while converting image to base64:",
          error
        );
      };
    }
  };

  const removeKey = (fileName) => {
    console.log("value", fileName);
    setBase64Image({ ...base64Image, [fileName]: "" });
  };

  useEffect(() => {
    if (state) {
      setBase64Image(state?.photo);
      setValue("firstName", state?.firstName);
      setValue("lastName", state?.lastName);
      setValue("address", state?.address);
      setValue("mobileNo", state?.mobileNo);
      setValue("alternateNo", state?.alternateNo);
      setValue("product", state?.product);
      setValue("issue", state?.issue);
      // setValue('photoFile',state?.photo)
      setValue("selectSlot", state?.selectSlot);
      setValue("totalAmt", state?.totalAmount);
      setValue("actualAmt", state?.actualAmount);
      setValue("netAmt", state?.netAmount);
      setValue("technicianShare", state?.technicianShare);
      setValue("ownerShare", state?.ownerShare);
    }
  }, []);

  const { mutate, isLoading, isError, isSuccess, reset } = useMutation(
    async (postData) => {
      try {
        console.log("postData", postData);
        const response = await axios.post(
          `${process.env.REACT_APP_HOST}/products`,
          // "https://api.2kvirtualworld.com/CRM/technicians",
          postData
        );
        // reset()
        setOpen("PRODUCT ADDED");
        return response.data;
      } catch (error) {
        console.error("Error in mutation:", error);

        throw error;
      }
    }
  );

  const UpdateFormValue = useMutation(
    (postData) =>
      axios.put(
        `${process.env.REACT_APP_HOST}/requests/${state?.id}`,
        postData
      ),
    {
      onSuccess: (data) => {
        // alert("successfully updated.");
        setUpdateOpen("PRODUCT UPDATED");
        // Navigate("/viewUserManagement");
      },
      onError: (error) => {
        // alert(error)
        console("Mutation failed:", error);
      },
    }
  );

  // console.log("userForm", userForm);

  const getModelTypeOptions = () => {
    const normalizedProductType = productType?.toLowerCase();

    if (normalizedProductType === "fridge") {
      return (
        <>
          <option value="Single Door">Single Door</option>
          <option value="Double Door">Double Door</option>
          <option value="Side-by-Side Door">Side-by-Side Door</option>
          <option value="French Door">French Door</option>
        </>
      );
    } else if (normalizedProductType === "ac") {
      return (
        <>
          <option value="Split AC">Split AC</option>
          <option value="Cassette AC">Cassette AC</option>
        </>
      );
    } else if (normalizedProductType === "washing machine") {
      return (
        <>
          <option value="Top Load">Top Load</option>
          <option value="Front Load">Front Load</option>
          <option value="Semi-Automatic">Semi-Automatic</option>
          <option value="Fully-Automatic">Fully-Automatic</option>
        </>
      );
    }
    return <option value=""></option>;
  };
  const handleProductChange = (e) => {
    const value = e.target.value;
    setProductType(value);
  };

  const Submit = async (data) => {
    // console.log("data", data);
    const formValue = {
      service: data?.service,
      product: data?.product,
      modelName: data?.modelName,
      subProduct: tagsTextArray,
      photo: base64Image?.photoFile,
      complaint: userFormContainerArray,
      totalAmount: data?.totalAmt,
      actualAmount: "",
      netAmount: data?.netAmt,
      technicianShare: data?.technicianShare,
      ownerShare: data?.ownerShare,
    };
    console.log("formValue", formValue);

    if (state && state?.id) {
      UpdateFormValue.mutate(formValue);
    } else {
      mutate(formValue);
    }
    // handleImageInputChange(data?.aadharFile);
  };
  const KeyCodes = {
    comma: 188,
    enter: 13,
  };
  // const delimiters = [KeyCodes.comma, KeyCodes.enter];
  const handleDelete = (i) => {
    setTags(tags.filter((tag, index) => index !== i));
  };
  const handleAddition = (tag) => {
    // console.log('demo',tag.);

    setTags([...tags, tag]);
  };
  const handleDrag = (tag, currPos, newPos) => {
    const newTags = [...tags];
    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);
    setTags(newTags);
  };
  return (
    <div className="w-100 p-3 px-4 ">
      <div
        className="row bg-white px-3 pb-4"
        style={{
          height: "95vh",
          borderRadius: "10px",
          overflowY: "scroll",
          scrollbarWidth: "thin",
        }}
      >
        <header
          className="fs-2 fw-bold py-2 ps-4 mb-3 text-center"
          style={{
            height: "10%",
            position: "sticky",
            top: "0px",
            backgroundColor: "#fff",
          }}
        >
          ADD PRODUCT
        </header>
        <section>
          <form onSubmit={handleSubmit(Submit)}>
            <div style={{}}>
              <div className="addUser_ticket_label">
                <h4 className="fw-bold addrequest-heading"> Product Details</h4>
                <div className=" col-12 mb-3 d-flex">
                  <div className="col-6">
                    <label className="col-5 addUser_ticket_label">
                      Service
                    </label>
                    {/* <Controller
                      control={control}
                      name="service"
                      render={(field) => (
                        <InputField
                          addUserInputCss="--addUser_inputFields"
                          {...field}
                          type="text"
                        />
                      )}
                      // rules={{ required: true }}
                    /> */}
                    <Controller
                      control={control}
                      name="service"
                      render={({ field }) => (
                        <>
                          <input
                            {...field}
                            type="text"
                            className="--addUser_inputFields"
                            value={field.value} // Bind value to react-hook-form state
                            onChange={(e) => {
                              field.onChange(e); // Update react-hook-form state
                              handleProductChange(e); // Update the local productType state
                            }}
                          />
                        </>
                      )}
                    />
                  </div>
                </div>
                <div className=" col-12 mb-3 d-flex">
                  {/* <div className="col-6">
                    <label className="col-5 addUser_ticket_label">
                      Product
                    </label>
                    <Controller
                      control={control}
                      name="product"
                      render={(field) => (
                        <InputField
                          // style = {{margin}}
                          addUserInputCss=" --addUser_inputFields"
                          {...field}
                          type="text"
                        />
                      )}
                      // rules={{ required: true }}
                    />
                  </div> */}
                  <div className="col-6">
                    <label className="col-5 addUser_ticket_label">
                      Product Type
                    </label>
                    <Controller
                      name="modelType"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <select {...field} className="--addUser_inputFields">
                          {getModelTypeOptions()}{" "}
                        </select>
                      )}
                    />
                  </div>

                  <div className="col-6">
                    <label className="col-5 addUser_ticket_label">
                      Model Name
                    </label>
                    <Controller
                      control={control}
                      name="modelName"
                      render={(field) => (
                        <InputField
                          // style = {{margin}}
                          addUserInputCss=" --addUser_inputFields"
                          {...field}
                          type="text"
                        />
                      )}
                      // rules={{ required: true }}
                    />
                  </div>
                </div>
              </div>

              <div className="col-12 mb-3">
                <div className="d-flex ">
                  <label
                    style={{ width: "20%" }}
                    className="addUser_ticket_label"
                  >
                    Sub Product
                  </label>
                  <div
                    style={{
                      width: "80%",
                      backgroundColor: "#004BCB66",
                      marginLeft: "8px",
                      borderRadius: "6px",
                    }}
                  >
                    <Controller
                      control={control}
                      name="subProduct"
                      render={(field) => (
                        <ReactTags
                          // {...field}
                          // classNames="--addUser_inputFields"
                          // delimiters={delimiters}
                          handleDelete={handleDelete}
                          handleAddition={handleAddition}
                          handleDrag={handleDrag}
                          tags={tags}
                          placeholder=""
                        />
                      )}
                      // rules={{ required: true }}
                    />
                  </div>
                </div>
              </div>
              <div className="col-12 mb-3">
                <div className="d-flex">
                  <label
                    style={{ width: "20%" }}
                    className="addUser_ticket_label"
                  >
                    Photo
                  </label>

                  <label
                    for="photoImg"
                    className="col-8 ms-2 aadharFileInput text-center d-flex justify-content-center align-items-center "
                  >
                    UPLOAD A FILE HERE{" "}
                    <img className="h-75" src={addIcon} alt="" />
                  </label>
                  <img
                    style={{ height: "30px", marginLeft: "10px" }}
                    alt=""
                    src={`data:image/jpeg;base64,${base64Image?.photoFile}`}
                  />
                  {base64Image?.photoFile && (
                    <img
                      onClick={() => {
                        removeKey("photoFile");
                      }}
                      className=" ps-2 h-75"
                      src={wrong}
                      alt=""
                    />
                  )}

                  <Controller
                    control={control}
                    name="photoFile"
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <input
                        className=" ms-2 "
                        type="file"
                        name="uploadfile"
                        id="photoImg"
                        style={{ display: "none" }}
                        onChange={(e) =>
                          onChange(handleImageInputChange(e, "photoFile"))
                        }
                      />
                    )}
                    // rules={{ required: true }}
                  />
                </div>
              </div>
              <div className="col-12 mb-4">
                <div className="d-flex">
                  <label
                    style={{ width: "20%" }}
                    className="addUser_ticket_label"
                  >
                    Complaint
                  </label>

                  <div className="col-8">
                    <div
                      className=" col-12 fs-5 text-center ms-2 aadharFileInput"
                      onClick={() => {
                        append({ complaint: "" });
                      }}
                    >
                      Add Complaint
                    </div>
                    {fields.map((field, index) => {
                      return (
                        <div key={field.id}>
                          <div className="d-flex">
                            <input
                              className="col-12 mt-2 fs-5 text-center ms-2 aadharFileInput"
                              {...register(`userForm.${index}.complaint`)}
                              placeholder={`Enter complaint ${index + 1}`}
                              // defaultValue={field.productNam..e} // Important for default values
                            />
                            <img
                              {...register(`userForm.${index}.image`)}
                              onClick={() => {
                                console.log("index", index);
                                remove(index);
                              }}
                              className=" mt-2 ps-2 "
                              src={wrong}
                              alt=""
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>

              {/* <div className="col-12">
                <h4 className="fw-bold mb-4 addrequest-heading">
                  {" "}
                  Payment Details
                </h4>

                <div className="col-12 mb-2 d-flex justify-content-between">
                  <label
                    style={{ width: "20%" }}
                    className=" addUser_ticket_label"
                  >
                    Select Slot
                  </label>
                  <div className="col-6 me-5 d-flex justify-content-end">
                    <Controller
                      control={control}
                      name="selectSlot"
                      defaultValue={currentTime} // Use current time as default
                      render={({ field }) => (
                        <input
                          {...field}
                          type="time"
                          className="--addUser_inputFields"
                        />
                      )}

                      // rules={{ required: true }}
                    />
                  </div>
                </div>
                <div className="col-12 mb-2 d-flex justify-content-between">
                  <label
                    style={{ width: "20%" }}
                    className=" addUser_ticket_label"
                  >
                    Total Amount
                  </label>
                  <div className="col-6 me-5 d-flex justify-content-end">
                    <Controller
                      control={control}
                      name="totalAmt"
                      render={(field) => (
                        <InputField
                          addUserInputCss="--addUser_inputFields"
                          {...field}
                          type="text"
                        />
                      )}
                      // rules={{ required: true }}
                    />
                  </div>
                </div>

                <div className="col-12 mb-2 d-flex justify-content-between">
                  <label
                    style={{ width: "20%" }}
                    className=" addUser_ticket_label"
                  >
                    Net Amount
                  </label>
                  <div className="col-6 me-5 d-flex justify-content-end">
                    <Controller
                      control={control}
                      name="netAmt"
                      render={(field) => (
                        <InputField
                          addUserInputCss="--addUser_inputFields"
                          {...field}
                          type="text"
                        />
                      )}
                      // rules={{ required: true }}
                    />
                  </div>
                </div>

                <div className="col-12 mb-2 d-flex justify-content-between">
                  <label
                    style={{ width: "40%" }}
                    className=" addUser_ticket_label"
                  >
                    Technician Share
                  </label>
                  <div className="col-6 me-5 d-flex justify-content-end">
                    <Controller
                      control={control}
                      name="technicianShare"
                      render={(field) => (
                        <InputField
                          addUserInputCss="--addUser_inputFields"
                          {...field}
                          type="text"
                        />
                      )}
                      // rules={{ required: true }}
                    />
                  </div>
                </div>
                <div className="col-12 mb-2 d-flex justify-content-between">
                  <label
                    style={{ width: "20%" }}
                    className=" addUser_ticket_label"
                  >
                    Owner Share
                  </label>
                  <div className="col-6 me-5 d-flex justify-content-end">
                    <Controller
                      control={control}
                      name="ownerShare"
                      render={(field) => (
                        <InputField
                          addUserInputCss="--addUser_inputFields"
                          {...field}
                          type="text"
                        />
                      )}
                      // rules={{ required: true }}
                    />
                  </div>
                </div>
              </div> */}

              <div
                className="col-12 mt-4 addUser_submitBtn_field "
                // onClick={()=>{
                //   console.log("vanakam")
                // }}
              >
                <button className=" col-5 addUser_submitBtn" type="submit">
                  {isLoading ? (
                    <div className="spinner-border text-light" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  ) : (
                    "SUBMIT"
                  )}
                </button>
              </div>
            </div>
          </form>
        </section>
      </div>
      <AlertModal
        open={open}
        setOpen={setOpen}
        setUpdateOpen={setUpdateOpen}
        UpdateOpen={UpdateOpen}
        navigate={"/addProduct"}
        setErrorOpen={setErrorOpen}
        errorOpen={errorOpen}
      />
    </div>
  );
}
// <textarea
//   {...field}
//   className=" col-9 p-2  --addUser_companyName_inputField"
//   style={{ height: "100px" }}
// ></textarea>
// <InputField
//   // style = {{margin}}
//   addUserInputCss=" col-9 --addUser_companyName_inputField"
//   {...field}
//   type="text"
// />
//   <div
//     className=" col-9 --addUser_companyName_inputField"
//     style={{ height: "150px" }}
//   ></div>
