// import React from "react";
// import { useLocation } from "react-router-dom";
// import acIcon from "../../assets/images/ac.jpg";

// const Invoice = () => {
//   const { state } = useLocation();
//   const register = sessionStorage.getItem("userData");
//   let rolebase = JSON.parse(register);

//   const printInvoice = () => {
//     const printContent = document.getElementById("invoice").innerHTML;
//     const printWindow = window.open("", "_blank");
//     printWindow.document.write(`
//       <html>
//         <head>
//           <title>Print Invoice</title>
//           <style>
//             body { font-family: Arial, sans-serif; margin: 20px; }
//             img { max-width: 20%; height: 20%; }
//           </style>
//         </head>
//         <body onload="window.print(); window.close();">
//           ${printContent}
//         </body>
//       </html>
//     `);
//     printWindow.document.close();
//   };

//   return (
//     <>
//       <div className="invoiceMainContainer my-4">
//         <div className="row">
//           <div className="col-lg-12">
//             <div
//               style={{ position: "absolute", top: "15px", right: "5px" }}
//               className="d-flex gap-1"
//             >
//               <button className="btn btn-primary">Share</button>
//               <button className="btn btn-primary" onClick={printInvoice}>
//                 Print
//               </button>
//             </div>
//           </div>
//         </div>
//         <div className="invoiceMainBlock my-1" id="invoice">
//           <div className="text-center">
//             <span className="invoice-head py-1 px-2">INVOICE</span>
//           </div>
//           <div
//             className="row py-2 mt-3"
//             style={{ borderBottom: "1px solid black" }}
//           >
//             <div className="col-3 d-flex">
//               <div style={{ width: "100px", height: "100px" }}>
//                 <img src="/asset/logo.png" width="100%" height="100%" />
//               </div>
//             </div>
//             <div className="col-6 text-center">
//               <h6 className="invoice-title">CUSTOMER RELATION MANAGEMENT</h6>
//               <p className="invoice-text">{rolebase?.companyAddress}</p>
//               <p className="invoice-text">
//                 {rolebase?.place} - {rolebase?.pincode}.
//               </p>
//               <p className="invoice-text">Tamilnadu .</p>
//             </div>
//             <div className="col-3 d-flex align-items-end">
//               <p className="invoice-text">
//                 Ph.No : {rolebase?.mobileNumber} <br />
//                 {rolebase?.altMobileNumber}
//               </p>
//             </div>
//           </div>
//           <div className="row my-2 py-2">
//             <div className="col-12 d-flex">
//               <div className="col-3 invoice-text-format text-color">
//                 Request ID
//               </div>
//               <div className="col-6 invoice-text-format">
//                 : {rolebase?.userId}
//               </div>
//             </div>
//             <div className="col-12 d-flex">
//               <div className="col-3 invoice-text-format text-color">Name</div>
//               <div className="col-6 invoice-text-format">
//                 : {rolebase?.firstName} {rolebase?.lastName}
//               </div>
//             </div>
//             <div className="col-12 d-flex">
//               <div className="col-3 invoice-text-format text-color">
//                 Mobile Number
//               </div>
//               <div className="col-6 invoice-text-format">
//                 : {rolebase?.mobileNumber}
//               </div>
//             </div>
//             <div className="col-12 d-flex">
//               <div className="col-3 invoice-text-format text-color">
//                 Address
//               </div>
//               <div className="col-6 invoice-text-format">
//                 : {rolebase?.companyAddress}
//               </div>
//             </div>
//             <div className="col-12 d-flex">
//               <div className="col-3 invoice-text-format text-color">Place</div>
//               <div className="col-6 invoice-text-format">
//                 : {rolebase?.place}
//               </div>
//             </div>
//           </div>
//           <div className="row my-2 py-2">
//             <h6 className="invoice-title text-start my-3">PRODUCT DETAILS </h6>

//             <div className="col-8">
//               <div className="row d-flex">
//                 <div className="col-4 invoice-text-format text-color">
//                   Product
//                 </div>
//                 <div className="col-8 invoice-text-format">
//                   : {state?.product}
//                 </div>
//               </div>
//               <div className="row d-flex">
//                 <div className="col-4 invoice-text-format text-color">
//                   Model
//                 </div>
//                 <div className="col-8 invoice-text-format">
//                   : {state?.modelName}
//                 </div>
//               </div>
//               <div className="row d-flex">
//                 <div className="col-4 invoice-text-format text-color">
//                   Brand
//                 </div>
//                 <div className="col-8 invoice-text-format">
//                   : {state?.brandName}
//                 </div>
//               </div>
//               <div className="row d-flex">
//                 <div className="col-4 invoice-text-format text-color">
//                   Serial No
//                 </div>
//                 <div className="col-8 invoice-text-format">
//                   : {state?.serialNo}
//                 </div>
//               </div>
//             </div>
//             <div className="col-4 d-flex justify-content-start">
//               <div style={{ width: "100px", height: "100px" }}>
//                 <img
//                   className="img-fliud"
//                   src={acIcon}
//                   width="100%"
//                   height="100%"
//                   alt="no-image"
//                 />
//               </div>
//             </div>
//           </div>
//           <div className="row my-1 py-2">
//             <h6 className="invoice-title  my-3">PAYMENT DETAILS </h6>
//             <table>
//               <thead>
//                 <tr>
//                   <th className="invoice-table-head">Material Name</th>
//                   {/* <th className="invoice-table-head">Purchase Charge</th> */}
//                   <th className="invoice-table-head">Replace Charge</th>
//                   <th className="invoice-table-head">Service Charge</th>
//                   <th className="invoice-table-head">Total Amount</th>
//                 </tr>
//               </thead>
//               <tbody>
//                 <tr>
//                   <td className="invoice-table-data">
//                     {state?.replaceMaterialName || ""}
//                   </td>
//                   {/* <td className="invoice-table-data">
//                     {state?.purchaseMaterialCost || ""}
//                   </td> */}
//                   <td className="invoice-table-data">
//                     {state?.replaceMaterialCharge}
//                   </td>
//                   <td className="invoice-table-data">{state?.serviceCharge}</td>
//                   <td className="invoice-table-data">{state?.totalAmount}</td>
//                 </tr>
//               </tbody>
//             </table>
//             <footer className="my-3">
//               <p className="text-end invoice-text-format">
//                 <i>Authorized By</i>
//               </p>
//               <p className="footer-text">www.2kvirtualworld.com</p>
//             </footer>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default Invoice;

import React from "react";
import { useLocation } from "react-router-dom";

const Invoice = () => {
  const { state } = useLocation();
  const register = sessionStorage.getItem("userData");
  let rolebase = JSON.parse(register);

  const handlePrint = () => {
    const printContent = document.getElementById("invoiceMainBlock");
    const printWindow = window.open("", "", "height=800,width=800");

    // Write the HTML content to the new print window
    printWindow.document.write("<html><head><title>Invoice</title>");

    // Default inline styles to match your current web styles
    printWindow.document.write(`
      <style>
        body {
          font-family: Arial, sans-serif;
          margin: 0;
          padding: 0;
        }
        .invoiceMainBlock {
          padding: 20px;
          font-size: 14px;
          width: 100%;
          box-sizing: border-box;
        }
        .invoice-head {
          font-size: 22px;
          font-weight: bold;
          margin-bottom: 10px;
        }
        .invoice-title {
          font-size: 16px;
          font-weight: bold;
        }
        .invoice-text {
          font-size: 14px;
          margin-bottom: 5px;
        }
        .invoice-text-format {
          font-size: 14px;
          margin-bottom: 5px;
        }
        .table-head, .invoice-table-head {
          font-weight: bold;
          text-align: left;
          padding: 5px 10px;
        }
        .table-data, .invoice-table-data {
          padding: 5px 10px;
        }
        .footer-text {
          text-align: center;
          margin-top: 20px;
        }
        footer {
          text-align: center;
          margin-top: 20px;
        }
        .text-end {
          text-align: end;
        }
        .text-center {
          text-align: center;
        }
        .text-start {
          text-align: start;
        }
        table {
          width: 100%;
          border-collapse: collapse;
          margin-top: 10px;
        }
        table, th, td {
          border: 1px solid black;
        }
        th, td {
          padding: 8px;
        }

        /* Print-specific CSS */
        @media print {
          /* Apply flexbox to ensure that Request ID and its value are on the same line */
          .row.my-2.py-2 > .col-12.d-flex {
            display: flex;
            justify-content: space-between; /* Ensures content is spaced evenly on the same line */
            width: 50%;
          }

          /* Set each part (Request ID and value) to take up 50% of the width */
          .row.my-2.py-2 > .col-3.invoice-text-format {
            width: 50%;
          }

          .row.my-2.py-2 > .col-6.invoice-text-format {
            width: 50%;
          }

          /* Prevent text wrapping */
          .invoice-text-format {
            white-space: nowrap;
          }
        }
      </style>
    `);

    // Write the content of the invoice into the print window
    printWindow.document.write("<body>");
    printWindow.document.write(printContent.innerHTML);
    printWindow.document.write("</body></html>");

    // Close the document and trigger the print dialog
    printWindow.document.close();
    printWindow.print();
  };

  return (
    <div className="invoiceMainContainer my-4">
      <div className="row">
        <div className="col-lg-12">
          <div
            style={{ position: "absolute", top: "15px", right: "5px" }}
            className="d-flex gap-1"
          >
            {/* <button className="btn btn-primary">Share</button> */}
            <button className="btn btn-primary" onClick={handlePrint}>
              Print
            </button>
          </div>
        </div>
      </div>
      <div className="invoiceMainBlock my-1" id="invoiceMainBlock">
        <div className="text-center">
          <span className="invoice-head py-1 px-2">INVOICE</span>
        </div>
        <div
          className="row py-2 mt-3"
          style={{ borderBottom: "1px solid black" }}
        >
          <div className="col-3 d-flex">
            <div style={{ width: "100px", height: "100px" }}>
              <img src="/asset/logo.png" width="100%" height="100%" />
            </div>
          </div>
          <div className="col-6 text-center">
            <h6 className="invoice-title">CUSTOMER RELATION MANAGEMENT</h6>
            <p className="invoice-text">{rolebase?.companyAddress}</p>
            <p className="invoice-text">
              {rolebase?.place} - {rolebase?.pincode}.
            </p>
            <p className="invoice-text">Tamilnadu .</p>
          </div>
          <div className="col-3 d-flex align-items-end">
            <p className="invoice-text">
              Ph.No : {rolebase?.mobileNumber} <br />
              {rolebase?.altMobileNumber}
            </p>
          </div>
        </div>
        <div className="row my-2 py-2">
          <div className="col-12 d-flex">
            <div className="col-3 invoice-text-format text-color">
              Request ID
            </div>
            <div className="col-6 invoice-text-format">
              : {rolebase?.userId}
            </div>
          </div>
          <div className="col-12 d-flex">
            <div className="col-3 invoice-text-format text-color">Name</div>
            <div className="col-6 invoice-text-format">
              : {rolebase?.firstName} {rolebase?.lastName}
            </div>
          </div>
          <div className="col-12 d-flex">
            <div className="col-3 invoice-text-format text-color">
              Mobile Number
            </div>
            <div className="col-6 invoice-text-format">
              : {rolebase?.mobileNumber}
            </div>
          </div>
          <div className="col-12 d-flex">
            <div className="col-3 invoice-text-format text-color">Address</div>
            <div className="col-6 invoice-text-format">
              : {rolebase?.companyAddress}
            </div>
          </div>
          <div className="col-12 d-flex">
            <div className="col-3 invoice-text-format text-color">Place</div>
            <div className="col-6 invoice-text-format">: {rolebase?.place}</div>
          </div>
        </div>
        <span
          className="invoice-title text-start my-3"
          style={{ borderBottom: "2px solid black" }}
        >
          PRODUCT DETAILS{" "}
        </span>

        <div className="row my-2 py-2 mx-2">
          <table>
            <thead>
              <tr>
                <th className="table-head">Product</th>
                <th className="table-head">Model</th>
                <th className="table-head">Brand</th>
                <th className="table-head">Serial No</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="table-data">{state?.product}</td>
                <td className="table-data">{state?.modelName}</td>
                <td className="table-data">{state?.brandName}</td>
                <td className="table-data">{state?.serialNo}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <span
          className="invoice-title text-start my-3"
          style={{ borderBottom: "2px solid black" }}
        >
          PAYMENT DETAILS{" "}
        </span>

        <div className="row my-1 py-2">
          <table>
            <thead>
              <tr>
                <th className="invoice-table-head">Material Name</th>
                <th className="invoice-table-head">Replace Charge</th>
                <th className="invoice-table-head">Service Charge</th>
                <th className="invoice-table-head">Total Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="invoice-table-data">
                  {state?.replaceMaterialName || ""}
                </td>
                <td className="invoice-table-data">
                  {state?.replaceMaterialCharge}
                </td>
                <td className="invoice-table-data">{state?.serviceCharge}</td>
                <td className="invoice-table-data">{state?.totalAmount}</td>
              </tr>
            </tbody>
          </table>
          <footer className="my-3">
            <p className="text-end invoice-text-format">
              <i>Authorized By</i>
            </p>
            <p className="footer-text">www.2kvirtualworld.com</p>
          </footer>
        </div>
      </div>
    </div>
  );
};

export default Invoice;
