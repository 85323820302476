import React from "react";
import Login from "../../common/components/logincomp/Login";

const LoginScreen = () => {
  return (
    <>
      <section className="loginMainContainer">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 loginMainBlock">
              <Login />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default LoginScreen;
