import React from "react";

const DeletePopup = ({
  isOpen,
  onClose,
  children,
  backgroundColor,
  maxWidth,
}) => {
  if (!isOpen) return null;

  return (
    <div className="popup-overlay">
      <div className="popup-content" style={{ backgroundColor, maxWidth }}>
        {children}
      </div>
    </div>
  );
};

export default DeletePopup;
